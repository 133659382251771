const faqs = [
  {
    question: "Beneficios del seguro de viaje internacional",
    answer: (
      <div>
        <p>- Cobertura médica: El seguro médico de viaje internacional ofrece protección en caso de enfermedad o lesiones durante tu estadía en el extranjero. Esto incluye atención médica, hospitalización, medicamentos y evacuación médica en casos graves.</p><br/>
        <p>- Cancelación y retraso de viajes: Si debes cancelar tu viaje debido a circunstancias imprevistas, como enfermedad grave, muerte en la familia o desastres naturales, el seguro de viaje puede reembolsar los gastos no reembolsables. También te compensará por los retrasos prolongados que puedas experimentar.</p><br/>
        <p>- Pérdida de equipaje: Si tu equipaje se pierde o es robado durante el viaje, el seguro de viaje te ayudará a reemplazar tus pertenencias personales. Esto incluye ropa, artículos de tocador y otros objetos de valor.</p><br/>
        <p>- Asistencia legal y responsabilidad civil: Si te encuentras en problemas legales mientras viajas al extranjero, el seguro de viaje puede proporcionarte asistencia legal. Además, te cubrirá en caso de que causes daños a terceros y debas hacer frente a una reclamación de responsabilidad civil.</p><br/>
    
      </div>
    ),
  },
  {
    question: "¿Cómo elegir el mejor seguro de viaje internacional?",
    answer: (
      <div>
      <p>- Cobertura médica adecuada: Verifica que el seguro de viaje ofrezca una cobertura médica suficiente para cubrir tus necesidades personales. Considera factores como la duración del viaje, el destino y cualquier condición médica preexistente que tengas.</p><br/>
      <p>-Cobertura geográfica: Asegúrate de que el seguro de viaje cubra todos los destinos que tienes en mente. Algunas pólizas pueden tener restricciones para ciertos países o regiones.</p><br/>
      <p>- Política de cancelación: Lee detenidamente los términos y condiciones de cancelación del seguro de viaje. Asegúrate de comprender las circunstancias en las que puedes recibir un reembolso en caso de cancelación.</p><br/>
      <p>- Valoraciones y opiniones: Investiga y lee las opiniones de otros viajeros sobre las compañías de seguros que estás considerando. Esto te dará una idea de su reputación y servicio al cliente.</p><br/>
  
    </div>
    ),
  },
 
];

export default faqs;
